import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-firma-dialog',
  templateUrl: './firma-dialog.component.html',
  styleUrls: ['./firma-dialog.component.css']
})
export class FirmaDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<FirmaDialogComponent>
  ) {}

    // Diese Funktion wird aufgerufen, wenn der Benutzer auf eine Firma klickt
    selectFirma(firma: any): void {
      // Schließe den Dialog und gebe die ausgewählte Firma zurück
      this.dialogRef.close(firma);
    }
}

