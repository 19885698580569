<h2 mat-dialog-title>Bitte wählen Sie eine Firma</h2>
<mat-dialog-content>
  <mat-list>
    
    <mat-list-item *ngFor="let firma of data.firmen" (click)="selectFirma(firma)">
     
     <a class="modalMouse">{{ firma.Firma }}</a> 
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
