<div class="container p-1 mt-2 text-center" *ngIf="falscherKursCode" [@fadeIn]>
  <div class="row">
    <div class="col fs-5 fw-bold py-3">
      Die von Ihnen eingegebenen Daten sind ungültig. Bitte wenden Sie sich an
      Ihren Ansprechpartner:in.
    </div>
  </div>
</div>

<div class="row d-flex justify-content-center" *ngIf="header" [@fadeIn]>
  <img src="{{ gliederung?.OL_PicURL_Logo }}" style="max-width: 200px" />
</div>

<div class="container p-1 mt-2 text-center" *ngIf="fehler" [@fadeIn]>
  <div class="row">
    <div class="col fs-5 fw-bold py-3">
      Der von Ihnen ausgewählte Kurs ist abgelaufen oder ist nicht verfügbar.
      Bitte wenden Sie sich an Ihren Ansprechpartner:in.
    </div>
  </div>
  <div class="row text-center py-3">
    <div class="row pb-3">
      <div class="col" [innerText]="gliederung?.OL_Text_Ansprechpartner"></div>
    </div>
    <div class="row">
      <div class="col text-center">{{ gliederung?.Organisation }}</div>
    </div>
    <div class="row">
      <div class="col" [innerText]="gliederung?.OL_Anschrift_Name"></div>
    </div>
    <div class="row">
      <div class="col text-center">
        {{ gliederung?.OL_Anschrift_Strasse }}
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        {{ gliederung?.OL_Anschrift_PLZ }} {{ gliederung?.OL_Anschrift_Ort }}
      </div>
    </div>
  </div>
</div>

<div class="container p-1 mt-2 text-center" *ngIf="anzeige" [@fadeIn]>
  <h2>FirmenManager Selbstverwaltung</h2>
  <h5>Verwalten Sie Ihre Mitarbeitenden selbst und melden Sie diese an.</h5>
  <div class="row pt-3">
    <div class="col">
      Sie melden im Namen der Firma
      <strong>{{ firma?.Firma }}</strong> Ihre Mitarbeitenden an.
    </div>
  </div>

  <div class="row pt-2">
    <div class="col">
      Folgender Kurs ist ausgewählt:
      <p class="pt-2" style="margin: 0; padding: 0">
        <strong>{{ kurs?.Kursname }}</strong>
      </p>
      <p style="margin: 0; padding: 0">
        <strong>{{ formatDate(kurs?.Datum) }}</strong>
      </p>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col">
      Aktuell belegte Plätze:
      <strong>{{ anzahl?.Anzahl }} / {{ kurs?.MaxTNZahl }}</strong>
    </div>
  </div>
  <div class="row pt-2">
    <div
      class="col fw-bold"
      *ngIf="freiePlaetze <= 0"
      style="color: rgb(255, 28, 28)"
    >
      Für diesen Kurs ist kein freier Platz mehr verfügbar
    </div>
    <div
      class="col py-2"
      *ngIf="freiePlaetze < 2 && freiePlaetze > 0"
      style="background-color: green; color: white"
    >
      Für diesen Kurs ist noch <strong>{{ freiePlaetze }}</strong> freier Platz
      verfügbar
    </div>
    <div
      class="col py-2"
      *ngIf="freiePlaetze > 1"
      style="background-color: green; color: white"
    >
      Für diesen Kurs sind noch <strong>{{ freiePlaetze }}</strong> freie Plätze
      verfügbar
    </div>
  </div>
  <!-- Button trigger modal -->
  <div class="row pt-3" *ngIf="freiePlaetze > 0">
    <!-- <div class="row "><div class="col d-flex align-items-center">
    <div class="example-listbox-container">
      <label class="example-listbox-label" id="example-toppings-label">
        Ihre Mitarbeitenden
      </label>
      <ul
        cdkListbox
        cdkListboxMultiple
        aria-labelledby="example-toppings-label"
        class="example-listbox"
      >
       
        <li [cdkOption]="topping" class="example-option text-start" *ngFor="let topping of toppings">{{ topping }}</li>
        
      </ul>
    </div>
    <button type="button"
    class="btn btn-outline-success m-2 ">Auswählen</button>
  <div class="example-listbox-container">
    <label class="example-listbox-label" id="example-toppings-label">
      Ihre Mitarbeitenden
    </label>
    <ul
      cdkListbox
      cdkListboxMultiple
      [(ngModel)]="order"
      aria-labelledby="example-toppings-label"
      class="example-listbox"
    >
     
      <li [cdkOption]="topping" class="example-option text-start" *ngFor="let topping of order">{{ topping }}</li>
      
    </ul>
</div>   </div></div> -->
    <div class="col my-2">
      <button
        type="button"
        class="btn btn-outline-success"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        (click)="resetForm()"
      >
        Teilnehmenden hinzufügen
      </button>
    </div>
  </div>
</div>
<div class="container" *ngIf="anzeige" [@fadeIn]>
  <div
    class="row my-2 py-2"
    style="
      border-style: solid;
      border-width: 1px;
      border-radius: 0.5rem;
      border-color: lightgray;
    "
    *ngFor="let teilnehmer of teilnahmen"
  >
    <div class="col lg-4">
      <p style="margin: 0; padding: 0"><strong>Name, Vorname: </strong></p>
      <p style="margin: 0; padding: 0">
        {{ teilnehmer?.Nachname }}, {{ teilnehmer?.Vorname }}
      </p>
      <p style="margin: 0; padding: 0"><strong>Geburtsdatum:</strong></p>
      <p style="margin: 0; padding: 0">{{ formatDate(teilnehmer?.GebDat) }}</p>
    </div>
    <div class="col">
      <p style="margin: 0; padding: 0"><strong>Adresse:</strong></p>
      <p style="margin: 0; padding: 0">{{ teilnehmer?.Strasse }}</p>
      <p style="margin: 0; padding: 0">
        {{ teilnehmer?.PLZ }} {{ teilnehmer?.Ort }}
      </p>
    </div>
    <div class="col text-end align-self-center">
      <button
        type="button"
        class="btn btn-outline-secondary m-2"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop2"
        (click)="setValues(teilnehmer)"
      >
        Bearbeiten
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary m-2"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop3"
        (click)="getTeilnehmer(teilnehmer)"
      >
        Abmelden
      </button>
    </div>
  </div>
</div>
<!-- Modal -->
<div
  class="modal fade"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">
          Neuen Teilnehmenden anmelden
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body mx-2">
        <form
          [formGroup]="secondFormGroup"
          (ngSubmit)="onSubmit(secondFormGroup)"
        >
          <div class="form-group">
            <div class="row">
              <label for="firma" class="col-form-label fw-bold">Firma</label>
            </div>
            <div class="row">
              <div class="col">{{ firma?.Firma }}</div>
            </div>
            <div class="row">
              <label for="anrede" class="col-form-label fw-bold">Anrede </label>
            </div>
            <div class="row">
              <select class="form-select" id="anrede" formControlName="anrede">
                <option value="">keine Angabe</option>
                <option value="m">Herr</option>
                <option value="w">Frau</option>
              </select>
            </div>

            <div class="row">
              <label for="vorname" class="col-form-label fw-bold"
                >Vorname *
              </label>
            </div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                id="vorname"
                formControlName="vorname"
              />
            </div>

            <div class="row">
              <label for="name" class="col-form-label fw-bold">Nachname *</label>
            </div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                id="name"
                formControlName="name"
              />
            </div>

            <div class="row">
              <label for="gebdat" class="col-form-label fw-bold"
                >Geburtsdatum
                @if(gliederung.SV_BD_req === '1') {*}</label
              >
            </div>
            <div class="row">
              <input
                type="date"
                class="form-control"
                id="gebdat"
                formControlName="gebdat"
              />
            </div>

            <div class="row">
              <label for="strasse" class="col-form-label fw-bold">Straße</label>
            </div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                id="strasse"
                formControlName="strasse"
              />
            </div>
            <div class="row">
              <label for="plz" class="col-form-label fw-bold">PLZ</label>
            </div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                id="plz"
                formControlName="plz"
              />
            </div>
            <div class="row">
              <label for="ort" class="col-form-label fw-bold">Ort</label>
            </div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                id="ort"
                formControlName="ort"
              />
            </div>
            <div class="row">
              <label for="Telefon" class="col-form-label fw-bold"
                >Telefon</label
              >
            </div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                id="telefon"
                formControlName="telefon"
              />
            </div>
            <div class="row">
              <label for="e-mail" class="col-form-label fw-bold">E-Mail</label>
            </div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                id="e-mail"
                formControlName="email"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Abbrechen
              </button>
            </div>

            <div class="col">
              <button
                type="submit"
                class="btn btn-outline-dark"
                data-bs-dismiss="modal"
                [disabled]="!secondFormGroup.valid"
              >
                Teilnehmenden anmelden
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="staticBackdrop2"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdrop2Label"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdrop2Label">
          Teilnehmendendaten ändern
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body mx-2">
        <form
          [formGroup]="secondFormGroup"
          (ngSubmit)="update(secondFormGroup)"
        >
          <div class="form-group">
            <div class="row">
              <label for="firma" class="col-form-label fw-bold">Firma</label>
            </div>
            <div class="row">
              <div class="col">{{ firma?.Firma }}</div>
            </div>

            <div class="row">
              <label for="anrede" class="col-form-label fw-bold">Anrede </label>
            </div>
            <div class="row">
              <select class="form-select" id="anrede" formControlName="anrede">
                <option value="">keine Angabe</option>
                <option value="m">Herr</option>
                <option value="w">Frau</option>
              </select>
            </div>

            <div class="row">
              <label for="vorname" class="col-form-label fw-bold"
                >Vorname
              </label>
            </div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                id="vorname"
                formControlName="vorname"
              />
            </div>

            <div class="row">
              <label for="name" class="col-form-label fw-bold">Nachname</label>
            </div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                id="name"
                formControlName="name"
              />
            </div>

            <div class="row">
              <label for="gebdat" class="col-form-label fw-bold"
                >Geburtsdatum</label
              >
            </div>
            <div class="row">
              <input
                type="date"
                class="form-control"
                id="gebdat"
                formControlName="gebdat"
              />
            </div>

            <div class="row">
              <label for="strasse" class="col-form-label fw-bold">Straße</label>
            </div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                id="strasse"
                formControlName="strasse"
              />
            </div>
            <div class="row">
              <label for="plz" class="col-form-label fw-bold">PLZ</label>
            </div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                id="plz"
                formControlName="plz"
              />
            </div>
            <div class="row">
              <label for="ort" class="col-form-label fw-bold">Ort</label>
            </div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                id="ort"
                formControlName="ort"
              />
            </div>
            <div class="row">
              <label for="Telefon" class="col-form-label fw-bold"
                >Telefon</label
              >
            </div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                id="telefon"
                formControlName="telefon"
              />
            </div>
            <div class="row">
              <label for="e-mail" class="col-form-label fw-bold">E-Mail</label>
            </div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                id="e-mail"
                formControlName="email"
              />
            </div>
          </div>
          <div class="row pt-3 text-center">
            <div class="col">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Abbrechen
              </button>
            </div>

            <div class="col">
              <button
                type="submit"
                class="btn btn-outline-dark"
                data-bs-dismiss="modal"
              >
                Daten ändern
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="staticBackdrop3"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdrop3Label"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdrop3Label">
          Teilnehmenden vom Kurs abmelden
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body mx-2">
        <form
          [formGroup]="secondFormGroup"
          (ngSubmit)="deleteTeilnahme(teilnehmerdelete.TnID)"
        >
          <p>
            Sind Sie sicher, dass Sie den unterstehenden Teilnehmenden vom Kurs
            abmelden wollen?
          </p>

          <p>
            {{ teilnehmerdelete?.Nachname }}, {{ teilnehmerdelete?.Vorname }}
          </p>
          <div class="row pt-3 text-center">
            <div class="col">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Abbrechen
              </button>
            </div>

            <div class="col">
              <button
                type="submit"
                class="btn btn-outline-dark"
                data-bs-dismiss="modal"
              >
                Teilnehmenden abmelden
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<hr />
<footer
  class="fixed-bottom footer mt-2"
  [@fadeIn]
  style="background-color: {{ gliederung?.OL_Footer_BGColor }};"
>
  <hr style="padding: 0px; margin: 0px" />
  <div
    class="row p-2 footer-text mx-2"
    style="color:{{ gliederung?.OL_Footer_FontColor }}; "
  >
    <div class="col">
      &copy; Copyright {{year}}
      <a
        class="mouse"
        (click)="openKIS()"
        style="color: {{ gliederung?.OL_Footer_FontColor }}; "
      >
        <u>KISpro</u></a
      >
    </div>
    <div class="col text-end">
      <a
        class="mouse"
        (click)="openImpressum()"
        style="color: {{ gliederung?.OL_Footer_FontColor }}; "
        >Impressum</a
      >
      |
      <a
        class="mouse"
        (click)="openDatenschutz()"
        style="color: {{ gliederung?.OL_Footer_FontColor }};"
        >Datenschutz</a
      >
    </div>
  </div>
</footer>
