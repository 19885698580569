<div class="container align-items-center justify-content-center p-3 shadow" style="border-radius: 1rem;" [@fadeIn]>
<div class="row fs-4 fw-bold">
    <div class="col">Unser Kursangebot</div></div>
<div class="row pb-3">
    <div class="col">Hier sehen Sie eine Übersicht unserer angebotenen Kurse.</div></div>
    <div class="row"> <div class="col-1"><div class="quadrat2"> </div> </div> <div class="col">öffentliche Kurse (gemischte Teilnehmende, auch aus anderen Firmen bzw. Privatteilnehmende)</div> </div>
    <div class="row pb-3"> <div class="col-1"><div class="quadrat1"> </div> </div> <div class="col">interne Kurse (Dieser Kurs findet exklusiv für Ihre Mitarbeitenden statt)</div> </div>
<div class="row py-2">
    <div class="row">
<div>
            <input type="radio" id="alleKurseCheckbox" name="kurseanzeigen" (click)="showKurse('alle')">
            <label for="alleKurseCheckbox" class="px-1">Alle Kurse anzeigen</label>
        </div>
        <div>
            <input type="radio" id="interneKurseCheckbox" name="kurseanzeigen" (click)="showKurse('intern')">
            <label for="interneKurseCheckbox" class="px-1">exklusiver Kurs für die Mitarbeitenden der Firma <strong>{{firma?.Firma}}</strong> </label>
        </div>
            <div>
            <input type="radio" id="öffentlicheKurseCheckbox" name="kurseanzeigen" (click)="showKurse('extern')">
            <label for="öffentlicheKurseCheckbox" class="px-1">öffentliche Kurse anzeigen</label>
        </div>
    </div>
    </div>
    <div class="table-responsive">
    <table class="table is-hoverable">
        <thead>
            <tr>
                <th scope="col"> Kursansicht</th>
    <th scope="col"> Datum    <a (click)="sortResult('Datum',true, KursList)" style="cursor: pointer;">↑
    </a><!-- eslint-disable --><a (click)="sortResult('Datum',false, KursList)" style="cursor: pointer;"> ↓</a>
 </th>

    <th scope="col"> Uhrzeit</th>
    <th scope="col"> Kurstitel <a (click)="sortResult('Kursname',true, KursList)" style="cursor: pointer;">↑
    </a><!-- eslint-disable --><a (click)="sortResult('Kursname',false, KursList)" style="cursor: pointer;"> ↓</a></th>
        <th scope="col">Ort</th>
        <th scope="col">Freie Plätze</th>
<th class="text-center" scope="col">  Option </th>
        </thead>
        <tbody>
            <tr class="clickable" *ngFor="let dataItem of KursList; index as i" [@fadeIn] [ngClass]="{'custom-color1': dataItem.FirmenID !== '0', 'custom-color2': dataItem.FirmenID ==='0'}" >
                <td class="text-center"><button type="button" class="btn btn-outline-secondary btn-sm" [routerLink]="['/kurse/', dataItem.KursID]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"  viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                      </svg>
                    </button></td>
                <td >{{convertDate(dataItem.Datum)}}</td>
                <td >{{ convertTime(dataItem.Startzeit)}}-{{ convertTime(dataItem.Endzeit)}}</td>
                <td >{{dataItem.Kursname}}</td>
                <td >{{dataItem.Ort}}</td>
                <td class="text-center"> {{dataItem.Anzahl}}</td>
                <td *ngIf="dataItem.Anzahl <= 0" class="text-danger text-center" > ausgebucht </td>
                <td  class="text-center" ><button type="button" class="btn btn-outline-secondary btn-sm" [routerLink]="['/kurse/', dataItem.KursID]" *ngIf="dataItem.Anzahl > 0">
                Mitarbeitende anmelden
                </button></td>

            </tr>
        </tbody>

    </table>
</div>
</div>


<div class="container align-items-center justify-content-center pt-3 my-3 shadow" style="border-radius: 1rem;" [@fadeIn]>
    <div class="row fs-4 fw-bold">
        <div class="col">Vergangene Kurse</div></div>
    <div class="row pb-3">
        <div class="col">Hier sehen Sie eine Übersicht über bereits durchgeführte Kurse.</div></div>
        <div class="table-responsive py-2 fw-bold" *ngIf="vergangeneList.length === 0">Ihre Mitarbeitende haben bis jetzt an keinem Kurs teilgenommen.</div>
           <div class="table-responsive" *ngIf="vergangeneList.length !== 0">
        <table class="table is-hoverable">
            <thead>
                <tr>
                    <th scope="col"> Kursansicht</th>
                    <th scope="col"> Kurscode</th>        
        <th scope="col"> Datum    <a (click)="sortResult2('Datum',true)" style="cursor: pointer;">↑
        </a><!-- eslint-disable --><a (click)="sortResult2('Datum',false)" style="cursor: pointer;"> ↓</a>
     </th>
        <th scope="col"> Kurstitel <a (click)="sortResult2('Kursname',true)" style="cursor: pointer;">↑
        </a><!-- eslint-disable --><a (click)="sortResult2('Kursname',false)" style="cursor: pointer;"> ↓</a></th>
            <th scope="col">Ort</th>
            </thead>
            <tbody>
                <tr class="clickable" *ngFor="let dataItem of vergangeneList; index as i" [@fadeIn] [ngClass]="{'custom-color1': dataItem.FirmenID !== '0', 'custom-color2': dataItem.FirmenID ==='0'}" >
                    <td class="text-center"><button type="button" class="btn btn-outline-secondary btn-sm" [routerLink]="['/vergangenekurse/', dataItem.KursID]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"  viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                          </svg>
                        </button></td>
                        <td> {{dataItem.KursCode}}</td>
                    <td >{{convertDate(dataItem.Datum)}}</td>
                    <td >{{dataItem.Kursname}}</td>
                    <td >{{dataItem.Ort}}</td>

    
                </tr>
            </tbody>
    
        </table>
    </div>
    </div>