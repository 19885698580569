import { Component, OnInit } from '@angular/core';
import { SelbstverwaltungService } from '../selbstverwaltung.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarVerticalPosition,
  MatSnackBarHorizontalPosition,
} from '@angular/material/snack-bar';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-selbstverwaltung',
  templateUrl: './selbstverwaltung.component.html',
  styleUrls: ['./selbstverwaltung.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class SelbstverwaltungComponent implements OnInit {
  constructor(
    private service: SelbstverwaltungService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}
  // Formvalidierung Buchstabenpattern.
  emailPattern = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  characterPattern = new RegExp(/^[\u00C0-\u017Fa-zA-Z- ]+$/);
  strassePattern = new RegExp(/^[\u00C0-\u017F.a-zA-Z0-9- ]+$/);
  plzPattern = new RegExp(/^[0-9 ]+$/);
  hausnummerPattern = new RegExp(/^[a-zA-Z0-9 ]+$/);
  telefonPattern = new RegExp(/^[0-9+ -]+$/);

  kurs: any;
  anzahl: any;
  firma: any;
  gliederung: any;
  secondFormGroup: UntypedFormGroup;
  freiePlaetze: any;
  teilnahmen: any = [];
  teilnehmerdelete: any;
  bedingung = false;
  impressum = false;
  datenschutz = false;

  falscherKursCode = false;
  fehler = false;
  anzeige = false;
  kursFehler = false;
  header = false;

  year = new Date().getFullYear();
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  green = 'green-snackbar';
  red = 'warn-snackbar';

  toppings = ['Extra Cheese', 'Mushrooms', 'Pepperoni', 'Sausage'];
  order: readonly string[] = [];

  ngOnInit(): void {
    this.generateForm();
    const kursCode = this.route.snapshot.paramMap.get('kursCode');
    const key = this.route.snapshot.paramMap.get('key');
    const companykey = this.route.snapshot.paramMap.get('companykey');

    this.service.getGliederung(kursCode, key).subscribe((data3: any) => {
      this.gliederung = data3;
      if(data3.SV_BD_req === '1')
      {
        this.secondFormGroup.controls['gebdat'].setValidators([Validators.required]);
        this.secondFormGroup.updateValueAndValidity();
      }
      this.bedingung = data3.OL_URL_Teilnahmebed !== undefined ? true : false;
      this.datenschutz = data3.OL_URL_Datenschutz !== undefined ? true : false;
      this.impressum = data3.OL_URL_Impressum !== undefined ? true : false;
    });

    this.service
      .getTeilnahme(kursCode, key, companykey)
      .subscribe((data: any) => {
        this.teilnahmen = data;
      });

    this.service.getKurse(kursCode, key, companykey).subscribe((data: any) => {
      if (data.status === 'KursCodeError') {
        this.falscherKursCode = true;
        this.fehler = false;
        this.header = true;
        this.anzeige = false;
        return;
      }
      if (data.status === 'KursError' && data.status !== 'KursCodeError') {
        this.fehler = true;
        this.kursFehler = true;
        this.anzeige = false;
        return;
      }
      this.kurs = data;
      this.service
        .getAnzahlTeilnahmen(kursCode, key, companykey)
        .subscribe((data2: any) => {
          this.anzahl = data2;
          this.freiePlaetze = Number(data.MaxTNZahl) - Number(data2.Anzahl);
        });
    });

    this.service.getFirma(kursCode, key, companykey).subscribe((data: any) => {
      if (data.status === 'FirmaError' && data.status !== 'KursCodeError') {
        this.fehler = true;
        this.header = true;
        return;
      } else {
        if (!this.kursFehler && !this.falscherKursCode) {
          this.header = true;
          this.firma = data;
          this.anzeige = true;
        } else {
          if (!this.falscherKursCode) {
            this.fehler = true;
          }
        }
      }
    });
  }



  // tslint:disable-next-line: typedef
  generateForm() {
    this.secondFormGroup = new UntypedFormGroup({
      tnID: new UntypedFormControl(''),
      anrede: new UntypedFormControl(''),
      vorname: new UntypedFormControl('', [Validators.required]),
      name: new UntypedFormControl('', [Validators.required]),
      gebdat: new UntypedFormControl(''),
      strasse: new UntypedFormControl(''),
      plz: new UntypedFormControl(''),
      ort: new UntypedFormControl(''),
      telefon: new UntypedFormControl(''),
      firma: new UntypedFormControl(''),
      email: new UntypedFormControl(''),
    });
  }

  // tslint:disable-next-line: typedef
  resetForm() {
    this.secondFormGroup.reset();
  }
  // tslint:disable-next-line: typedef
  onSubmit(form: UntypedFormGroup) {
    const val = {
      Geschlecht: form.value.anrede,
      Name: form.value.name,
      Vorname: form.value.vorname,
      GebDat: form.value.gebdat,
      FirmenID: Number(this.firma.FirmenID),
      Strasse: form.value.strasse,
      Ort: form.value.ort,
      PLZ: form.value.plz,
      TelMobil: form.value.telefon,
      Email: form.value.email,
    };
    const kursCode = this.route.snapshot.paramMap.get('kursCode');
    const key = this.route.snapshot.paramMap.get('key');
    const companykey = this.route.snapshot.paramMap.get('companykey');
    this.service
      .addTeilnahmen(val, kursCode, key)
      .subscribe((data: any) => {
        if (data.status === 'success') {
          this.openSnackBar(data.message, this.green, 'OK');
        }
        this.ngOnInit();
      });
  }

  // tslint:disable-next-line: typedef
  setValues(teilnehmerdata: any) {
    this.secondFormGroup.controls.tnID.setValue(teilnehmerdata.TnID);
    this.secondFormGroup.controls.anrede.setValue(teilnehmerdata.Geschlecht);
    this.secondFormGroup.controls.name.setValue(teilnehmerdata.Nachname);
    this.secondFormGroup.controls.vorname.setValue(teilnehmerdata.Vorname);
    this.secondFormGroup.controls.gebdat.setValue(teilnehmerdata.GebDat);
    this.secondFormGroup.controls.strasse.setValue(teilnehmerdata.Strasse);
    this.secondFormGroup.controls.ort.setValue(teilnehmerdata.Ort);
    this.secondFormGroup.controls.plz.setValue(teilnehmerdata.PLZ);
    this.secondFormGroup.controls.telefon.setValue(teilnehmerdata.Festnetz);
    this.secondFormGroup.controls.email.setValue(teilnehmerdata.Email);
  }

  // tslint:disable-next-line: typedef
  getTeilnehmer(teilnehmerdata: any) {
    this.teilnehmerdelete = teilnehmerdata;
  }

  // tslint:disable-next-line: typedef
  update(form: UntypedFormGroup) {
    const val = {
      TnID: form.value.tnID,
      Geschlecht: form.value.anrede,
      Name: form.value.name,
      Vorname: form.value.vorname,
      GebDat: form.value.gebdat,
      FirmenID: Number(this.firma.FirmenID),
      Strasse: form.value.strasse,
      Ort: form.value.ort,
      PLZ: form.value.plz,
      TelMobil: form.value.telefon,
      Email: form.value.email,
    };
    const kursCode = this.route.snapshot.paramMap.get('kursCode');
    const key = this.route.snapshot.paramMap.get('key');
    const companykey = this.route.snapshot.paramMap.get('companykey');
    this.service
      .updateTeilnehmer(val, kursCode, key, companykey)
      .subscribe((data: any) => {
        if (data.status === 'updateError') {
          this.openSnackBar(data.message, this.green, 'OK');
        }
        this.ngOnInit();
        if (data.status === 'success') {
          this.openSnackBar(data.message, this.green, 'OK');
        }
        this.ngOnInit();
      });
  }
  // tslint:disable-next-line: typedef
  deleteTeilnahme(teilnehmerID: any) {
    const kursCode = this.route.snapshot.paramMap.get('kursCode');
    const key = this.route.snapshot.paramMap.get('key');
    const companykey = this.route.snapshot.paramMap.get('companykey');
    this.service
      .deleteTeilnahme(teilnehmerID, kursCode, key, companykey)
      .subscribe((data: any) => {
        if (data.status === 'success') {
          this.openSnackBar(data.message, this.green, 'OK');
        }
        this.ngOnInit();
        setTimeout(() => {
          // tslint:disable-next-line: no-unused-expression
          location.reload();
        }, 1500);
      });
  }

  // tslint:disable-next-line: typedef
  formatDate(date: any) {
    if (date != null) {
      const [year, month, days] = date.split('-');
      return days + '.' + month + '.' + year;
    } else {
      return;
    }
  }

  // tslint:disable-next-line: typedef
  openSnackBar(message: string, panel: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5 * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: [panel],
    });
  }

  // tslint:disable-next-line: typedef
  openTeilnahme() {
    window.open(this.gliederung.OL_URL_Teilnahmebed, '_blank');
  }

  // tslint:disable-next-line: typedef
  openDatenschutz() {
    window.open(this.gliederung.OL_URL_Datenschutz, '_blank');
  }

  // tslint:disable-next-line: typedef
  openImpressum() {
    window.open(this.gliederung.OL_URL_Impressum, '_blank');
  }

  // tslint:disable-next-line: typedef
  openKIS() {
    window.open('https://www.kispro.de/login.php', '_blank');
  }


}
