import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import urls from 'src/assets/urls.json';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-zeige-buchbarekurse',
  templateUrl: './zeige-buchbarekurse.component.html',
  styleUrls: ['./zeige-buchbarekurse.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ZeigeBuchbarekurseComponent implements OnInit {
  constructor(private service: SharedService, private router: Router) {}

  KursList: any = [];
  vergangeneList: any = [];
  KursListWithoutFilter: any = [];
  vergangeneListWithoutFilter: any = [];
  firma: any;


  ngOnInit(): void {
    this.refreshKursList();
    this.getFirma();
    this.getVergangeneKursListe();
  }

    // tslint:disable-next-line: typedef
    getFirma() {
      const encryptedValue = sessionStorage.getItem('id');
      let firmenID = null;
      if (encryptedValue) {
        const bytes = CryptoJS.AES.decrypt(encryptedValue, urls.encryption);
        const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
        firmenID =  decryptedValue ? JSON.parse(decryptedValue) : null;
      }
      this.service.getFirmaByID(firmenID).subscribe((firma: any) => {
        console.log(firma);
        this.firma = firma;
      });
    }
  // tslint:disable-next-line: typedef
  refreshKursList() {
    this.service.getverfuegbareKurseList().subscribe((kurse: any) => {
      this.KursList = kurse;
      this.KursListWithoutFilter = kurse;
    });
  }

  getVergangeneKursListe(){
    this.service.getvergangeneKurseList().subscribe((kurse:any) =>{
this.vergangeneList = kurse;
this.vergangeneListWithoutFilter = kurse;
    });
  }

  // tslint:disable-next-line: typedef
  convertDate(date: any) {
    if (date != null) {
      const [year, month, days] = date.split('-');
      return days + '.' + month + '.' + year;
    } else {
      return;
    }
  }

  // tslint:disable-next-line: typedef
  convertTime(time: string) {
    return time.substring(0, time.length - 3);
  }

  // tslint:disable-next-line: typedef
  sortResult(prop: any, asc: any, kurs: any) {
    // tslint:disable-next-line: typedef
    this.KursList = kurs.sort((a: any, b: any) => {
      if (asc) {
        return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
      } else {
        return b[prop] > a[prop] ? 1 : b[prop] < a[prop] ? -1 : 0;
      }
    });
  }


    // tslint:disable-next-line: typedef
    sortResult2(prop: any, asc: any) {
      // tslint:disable-next-line: typedef
      this.vergangeneList = this.vergangeneListWithoutFilter.sort((a: any, b: any) => {
        if (asc) {
          return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
        } else {
          return b[prop] > a[prop] ? 1 : b[prop] < a[prop] ? -1 : 0;
        }
      });
    }

    showKurse(kurse: any)
     {
      switch(kurse){
      case 'alle':
        this.KursList = this.KursListWithoutFilter;
        break;
        case 'intern':
          this.KursList = this.KursListWithoutFilter.filter((x: { FirmenID: string; }) => x.FirmenID !== null && x.FirmenID !== '0');
        break;
        case 'extern':
          this.KursList = this.KursListWithoutFilter.filter((x: { FirmenID: string; }) => x.FirmenID === null || x.FirmenID === '0');
          break;
     }
    }
}
