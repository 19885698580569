import { Component, inject, OnInit } from '@angular/core';
import { RegistrationService } from '../registration.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import urls from 'src/assets/urls.json';

import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class RegistrationComponent implements OnInit {
  snackBar = inject(MatSnackBar);
  angForm: UntypedFormGroup;
  constructor(
    private service: RegistrationService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.angForm = this.fb.group({
      email: [''],
      password1: ['', Validators.required],
      password2: ['', Validators.required],
      mitarbeitende: ['', Validators.required],
      ersthelfende: ['', Validators.required],
    });
  }

  registration: any;
  success: any;
  failed: any;
  gliederung: any;
  firma: any;

  spinner = false;

  fehlerPasswort: any;
  ngOnInit(): void {
    const startKey = this.route.snapshot.paramMap.get('startkey');
    this.service.getRegistration(startKey).subscribe((data: any) => {
      if (data.status === 'error') {
        this.router.navigate(['login']);
        return;
      }
      this.registration = data;
      this.angForm.controls.email.setValue(data.LoginEmail);
      this.angForm.controls.email.disable();
    });
    this.service.getGliederung(startKey).subscribe((data: any) => {
      if (data.status === 'error') {
        this.router.navigate(['login']);
        return;
      }
      this.gliederung = data;
    });
    this.service.getFirma(startKey).subscribe((data: any) => {
      if (data.status === 'error') {
        this.router.navigate(['login']);
        return;
      }
      this.firma = data;
      if (data.BedarfErsthelfende !== null){
     this.angForm.controls.ersthelfende.setValue(data.BedarfErsthelfende);
    }
      if (data.Mitarbeiter !== null){
      this.angForm.controls.mitarbeitende.setValue(data.Mitarbeiter);
     }
    });
  }

  // tslint:disable-next-line: typedef
  postdata(angForm: any) {
    this.spinner = true;
    const password = angForm.value.password1;
    const anzahlMA = angForm.value.mitarbeitende;
    const anzahlEH = angForm.value.ersthelfende;
    const key = this.route.snapshot.paramMap.get('startkey');
    const hash = CryptoJS.SHA512(password).toString(CryptoJS.enc.Hex);
    this.service
      .closeRegistration(key, hash, anzahlMA, anzahlEH)
      .subscribe((data: any) => {
        console.log(data);
        if (data.status === 'error') {
          this.failed = true;
        } else {
          setTimeout(() => {
            this.spinner = false;
            this.success = true;
          }, 2000);
        }
      });
  }

  // tslint:disable-next-line: typedef
  get validatePasswortForm() {
    if (this.angForm.value.password1 === this.angForm.value.password2) {
      return true;
    } else {
      return false;
    }
  }

  // tslint:disable-next-line: typedef
  openSnackBar(message: string, fehler: boolean, action: string) {
    if (fehler) {
      this.snackBar.open(message, action, {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 3 * 1000,
        panelClass: 'success-snackbar',
      });
    }
    // tslint:disable-next-line: align
    else {
      this.snackBar.open(message, action, {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 3 * 1000,
        panelClass: 'success-snackbar',
      });
    }
  }

  // tslint:disable-next-line: typedef
  openDatenschutz() {
    window.open(urls.datenschutz, '_blank');
  }

  // tslint:disable-next-line: typedef
  openImpressum() {
    window.open(urls.impressum, '_blank');
  }

  // tslint:disable-next-line: typedef
  openKIS() {
    window.open(urls.kis, '_blank');
  }
}
