{
  "APIUrlselbst" : "https://fmbeta.kispro.de/selbstverwaltung.php",
  "APIUrlfirmen" : "https://fmbeta.kispro.de/firmenmanager2.php",
  "APIUrllogin" : "https://fmbeta.kispro.de/login.php",
  "APIUrllogout" : "https://fmbeta.kispro.de/logout.php",
  "APITeilnehmerbescheinigung" : "https://kispro.de/tnbdownload.php",
  "impressum" : "https://www.kispro.de/impressum.html",
  "datenschutz" : "https://www.kispro.de/datenschutz.html",
  "registrierung" : "https://fmbeta.kispro.de/registration.php",
  "kis": "https://www.kispro.de/login.php",
  "tnb": "https://downloads.kispro.de/tnbdownload.php?k=",
  "encryption": "7Sc4tbjL3UvyMmBkmJ3m"
}