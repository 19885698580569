import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vergangene-kurse',
  templateUrl: './vergangene-kurse.component.html',
  styleUrls: ['./vergangene-kurse.component.css']
})
export class VergangeneKurseComponent implements OnInit {

  constructor(    private route: ActivatedRoute,
    private service: SharedService) { }


    kursDetail: any;
    teilnehmende: any;
  ngOnInit(): void {
    this.getKursDetails();
  }

  getKursDetails(){
  const kursID = this.route.snapshot.paramMap.get('id');
  this.service.getVergangeneKursDetailById(kursID).subscribe((data: any) => {
    this.kursDetail = data;
  });
  this.service.getTeilnehmendeKurs(kursID).subscribe((data: any) => {
    this.teilnehmende = data;
  });
  
}
  // tslint:disable-next-line: typedef
  convertDate(date: any) {
    if (date != null) {
      const [year, month, days] = date.split('-');
      return days + '.' + month + '.' + year;
    } else {
      return;
    }
  }

      // tslint:disable-next-line: typedef
      convertTime(time: string) {
        if(time != null){
        return time.substring(0, time.length - 3);
      }
        return '-';
      }


  // tslint:disable-next-line: typedef
  goToLink(kursCode: string, teilnahmeCode: string, validierungsCode: string) {
    // tslint:disable-next-line: max-line-length
    const link =
      'https://downloads.kispro.de/tnbdownload.php?k=' +
      kursCode +
      '&t=' +
      teilnahmeCode +
      '&v=' +
      validierungsCode;

    window.open(link, '_blank');
  }
}
