<div class="container">
<h2 class="fw-bold mb-0 p-3 text-center">Mitarbeitenden hinzufügen</h2>
<form [formGroup]="profilForm" (ngSubmit)="addPersonal(profilForm)">
  <div class="form-group">
    <div class="row p-1">
      <label for="vorname" class="col-form-label fw-bold">Vorname*:</label>
    </div>
    <div class="row px-3">
      <input type="text" class="form-control form-control-sm" id="vorname"  formControlName="vorname"  />
    </div>
    <div class="row p-1">
      <label for="nachname" class="col-form-label fw-bold" 
        >Nachname*:</label
      >
    </div>
    <div class="row px-3">
      <input type="text" class="form-control form-control-sm" id="nachname"  formControlName="nachname" />
    </div>
    <div class="row p-1">
      <label for="gebdat" class="col-form-label fw-bold">Geburtsdatum             
        @if(gliederung?.FM_BD_req === '0'){*}:</label>
    </div>
    <div class="row px-3">
      <input
        type="date"
        placeholder="TT.MM.JJJJ"
        class="form-control"
        id="gebdat" 
        formControlName="geburtsdatum"
      />
    </div>
    <div class="row p-1">
      <label for="geschlecht" class="col-form-label fw-bold">Geschlecht:</label>
    </div>
    <div class="row px-3">
      <select class="form-select form-select-sm" aria-label="geschlecht" id="geschlecht"  formControlName="geschlecht">
        <option value="m">männlich</option>
        <option value="w">weiblich</option>
        <option value="d">divers</option>
        <option value="n">keine Angabe</option>
      </select>
    </div>
    <div class="row p-1">
      <label for="strasse" class="col-form-label fw-bold">Straße:</label>
    </div>
    <div class="row px-3">
      <input type="text" class="form-control form-control-sm" id="strasse"  formControlName="strasse"/>
    </div>
    <div class="row p-1">
      <label for="plz" class="col-form-label fw-bold">Postleitzahl:</label>
    </div>
    <div class="row px-3">
      <input type="text" class="form-control form-control-sm" id="plz"  formControlName="plz" />
    </div>
    <div class="row p-1">
      <label for="ort" class="col-form-label fw-bold">Ort</label>
    </div>
    <div class="row px-3">
      <input type="text" class="form-control form-control-sm" id="ort"  formControlName="ort"/>
    </div>
    <div class="row p-1">
      <label for="e-mail" class="col-form-label fw-bold">E-Mail:</label>
    </div>
    <div class="row px-3">
      <input type="text" class="form-control form-control-sm" id="e-mail"  formControlName="email"/>
    </div>
    <div class="row p-1">
      <label for="festnetz" class="col-form-label fw-bold">Festnetz:</label>
    </div>
    <div class="row px-3">
      <input type="text" class="form-control form-control-sm" id="festnetz"  formControlName="telefon"/>
    </div>
    <div class="row p-1">
      <label for="mobil" class="col-form-label fw-bold">Mobil:</label>
    </div>
    <div class="row px-3">
      <input type="text" class="form-control form-control-sm" id="mobil"  formControlName="mobil"/>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col m-3">
      <button
        type="submit"
        class="btn btn-outline-secondary"
        [disabled]="!profilForm.valid"
      >
        Mitarbeitenden hinzufügen
      </button>
    </div>
    <div class="col m-3">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="onNoClick()"
      >
        Abbrechen
      </button>
    </div>
  </div>
</form>
</div>